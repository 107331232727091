:root {
  --pink: #ef798a;
  --red: #ec483f;
  --red15: rgba(236, 73, 65, 0.15);
  --yellow: #fabc2a;
  --green: #44b97d;
  --green15: rgba(68, 185, 125, 0.15);
  --blue: #5a8eff;
  --blue10: #eff4ff;
  --navy: #296f93;
  --purple: #b9c4fe;
  --grey: #c4c4c4;
  --light-grey: #f2f2f2;
  --black: #2b303a;
  --white: #ffffff;
  --offwhite: #faf9f6;
}

body {
  margin: 0;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--black);
}

div.center {
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: var(--black);
}

a:hover {
  color: var(--grey);
}

h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 64px;
  text-align: center;
  color: var(--black);
}

h1.padded {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 10vw;
  padding-left: 10vw;
  margin-top: 5vh;
  margin-bottom: 16px;
}

.page-title {
  max-width: 736px;
  margin: 0 auto;
}

h1.right {
  text-align: right;
}

h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: var(--black);
  width: 100%;
  text-align: left;
  margin-bottom: 0;
  margin-top: 0;
}

h2.center {
  text-align: center;
}
h3.center {
  text-align: center;
}

h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: var(--black);
  text-align: left;
}
h4.center {
  text-align: center;
}

h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: var(--black);
  text-align: left;
}

input[type="email"],
input[type="password"],
input[type="text"],
input[type="tel"] {
  height: 56px;
  background: var(--white);
  border: 2px solid var(--blue);
  border-radius: 8px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--black);
  padding: 0px 16px 0px 16px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  max-width: 360px;
}

input.discount {
  border-radius: 0px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

input.wide {
  max-width: 736px;
}

input::placeholder {
  font-style: italic;
}

input::-webkit-input-placeholder {
  /* Edge */
  font-style: italic;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-style: italic;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
  border: 2px solid var(--blue);
  border-radius: 6px;
  box-shadow: none;
  background-color: white;
}

input[type="checkbox"]:after {
  content: "";
  display: block;
  width: 8px;
  height: 15px;
  border: solid var(--blue);
  border-width: 0 4px 4px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 8px;
  opacity: 0;
  transition: all ease-out 0.2s;
}

input[type="checkbox"]:checked:after {
  transition: all ease-out 0.2s;
  opacity: 1;
}

.blue {
  color: var(--blue);
}

.navy {
  color: var(--navy);
}

.pink {
  color: var(--pink);
}

.yellow {
  color: var(--yellow);
}

.green {
  color: var(--green);
}

.gear-spin-1 {
  animation: spin 3s linear infinite reverse;
}
.gear-spin-2 {
  animation: spin 4.5s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.small-print {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--black);
  width: 100%;
  max-width: calc(736px);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: auto;
  display: inline-block;
}

.tr-blue {
  color: var(--blue);
}

.signup-cont {
  display: flex;
  flex-direction: column;
  gap: 48px;
  z-index: 100;
}

.tr-page-cont {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-cont {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 16px 80px 16px;
  background-color: var(--black);
}

.footer-text {
  color: var(--white);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
}

.navbar-cont {
  padding-top: 40px;
  padding-right: 10vw;
  padding-left: 10vw;
  padding-bottom: 8px;
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar-content-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  width: 100%;
  max-width: 1200px;
}
.navbar-logo {
  height: 32px;
}

.navbar-spacing {
  height: 112px;
}

.plan-button-cont {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: 16px;
  flex-wrap: wrap;
}

.plan-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  height: 88px;
  max-width: 360px;
  width: 48%;
  background: var(--white);
  border: 2px solid var(--blue);
  color: var(--blue);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  vertical-align: middle;
  position: relative;
}

.plan-button-checked {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  height: 88px;
  max-width: 360px;
  width: 48%;
  background: var(--blue);
  border: 2px solid var(--blue);
  color: var(--white);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  vertical-align: middle;
}

.button-bubble {
  position: absolute;
  top: -18px;
  right: -20px;
  padding: 6px 16px 6px 16px;
  border-radius: 100px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--white);
  background-color: var(--green);
}

button.pill {
  padding: 4px 14px 4px 14px;
  border-radius: 100px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--white);
  background-color: var(--blue);
  border: 2px solid var(--blue);
  box-shadow: none;
  outline: none;
}
button.pill.secondary {
  background-color: var(--white);
  color: var(--blue);
}

.submit-button {
  height: 64px;
  padding: 0px 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: var(--white);
  border-radius: 100px;
  background-color: var(--blue);
  border: none;
  margin: 32px auto 0px auto;
  text-decoration: none;
}

.submit-button:disabled {
  opacity: 0.7;
}

.submit-button:hover {
  text-decoration: none;
  color: white;
  opacity: 0.7;
}

.discount-button {
  height: 56px;
  padding: 0px 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  white-space: nowrap;
  color: var(--white);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: var(--blue);
  border: none;
  margin: 0px auto 0px auto;
  text-decoration: none;
}

.discount-button:disabled {
  opacity: 0.7;
}

.discount-button:hover {
  text-decoration: none;
  color: white;
  opacity: 0.7;
}

.follow-button {
  width: auto;
  height: auto;
  padding: 8px 32px 8px 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: var(--white);
  border-radius: 100px;
  background-color: var(--pink);
  border: none;
  margin: 24px 0px 0px auto;
  cursor: pointer;
}

.follow-button.blue {
  background-color: var(--blue);
}

.submit-arrow {
  color: var(--white);
}

.age-cont {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 24px;
  width: 100%;
  max-width: 360px;
  align-items: center;
}

.signup-block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 16px;
  max-width: 736px;
  /* margin: auto; */
  /* width: 100%; */
  padding: 16px;
  overflow-y: hidden;
  /* box-sizing: border-box; */
  position: relative;
}

.edit-address-block{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  max-width: 736px;
  flex-wrap: wrap;
  width: 100%;
}

div.padded {
  max-width: 1000px;
  padding-left: 16px;
  padding-right: 16px;
}

.signup-block.discount {
  max-height: 168px;
  transition: all 1s ease;
  width: -webkit-fill-available;
}

.signup-inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  max-width: 750px;
  padding: 16px;
}

.signup-container {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.centered-page-container {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.price-block {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
  margin: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.price-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 736px;
  justify-content: space-between;
  margin-bottom: 8px;
}

.price-s-print {
  width: 100%;
  max-width: 736px;
  text-align: right;
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  padding-left: 30%;
  box-sizing: border-box;
}

.block-header {
  width: 100%;
  max-width: 736px;
  margin-top: 20px;
}
.block-width {
  width: 100%;
  max-width: 736px;
}

.block-header.with-expand {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.block-header.edit-address {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.expand-arrow {
  color: var(--black);
  transform: rotate(0deg);
  transition: all 0.5s ease;
}

.expand-arrow.close {
  transform: scaleY(-1);
}

.remove-discount {
  color: var(--black);
}

.workshop-block {
  width: 100%;
  margin-top: 48px;
  display: flex;
  flex-direction: row;
}

.workshop-image {
  width: 50%;
  height: 400px;
  object-fit: cover;
}

.workshop-text-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  padding: 0 5% 0 5%;
  gap: 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-image: url("../public/grid.webp");
  background-size: 600px;
}

.box-block {
  gap: 16px;
  max-width: 736px;
  padding: 16px;
  /* margin: auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.box-image {
  width: 100%;
  max-width: 736px;
  border-radius: 16px;
}

.box-name {
  position: absolute;
  transform: rotate(0deg);
  left: 47.4%;
  top: 23.5%;
  font-weight: 600;
  font-family: "Annie Use Your Telescope", cursive;
  /* font-family: 'Amatic SC', cursive; */
  font-weight: 700;
  font-size: 24px;
  color: #5f7082;
  opacity: 0.9;
  transform-origin: 0% 0%;
  font-weight: 20px;
}

.box-name.large {
  top: 24.3%;
  font-size: 20px;
}
/* .box-name {
  position: absolute;
  transform: rotate(19deg);
  left: 42.5%;
  top: 36.4%;
  font-weight: 600;
  color: var(--black);
  opacity: 0.9;
  transform-origin: 0% 0%;
} */

select:focus {
  font-size: 16px;
}

select:required:invalid {
  opacity: 0.7;
  font-style: italic;
}
/* option[value=""][disabled] {
  display: none;
} */

select {
  /* styling */
  background-color: var(--white);
  border: 2px solid var(--blue);
  border-radius: 8px;
  display: inline-block;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  height: 56px;
  padding: 0px 16px 0px 16px;
  width: 120px;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

select.full {
  width: 100%;
  max-width: 736px;
}

select.half {
  width: 100%;
  max-width: 360px;
}

select.minimal {
  background-image: linear-gradient(45deg, transparent 50%, var(--blue) 50%),
    linear-gradient(135deg, var(--blue) 50%, transparent 50%),
    linear-gradient(to right, var(--blue), var(--blue));
  background-position: calc(100% - 20px) calc(1em + 8px),
    calc(100% - 15px) calc(1em + 8px), calc(100% - 2.5em) calc(100% - 12px);
  background-size: 5px 5px, 5px 5px, 1.5px 32px;
  background-repeat: no-repeat;
}

select.minimal:focus {
  background-image: linear-gradient(45deg, var(--black) 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, var(--black) 50%),
    linear-gradient(to right, var(--black), var(--black));
  background-position: calc(100% - 15px) calc(1em + 6px),
    calc(100% - 20px) calc(1em + 6px), calc(100% - 2.5em) calc(100% - 12px);
  background-size: 5px 5px, 5px 5px, 1.5px 32px;
  background-repeat: no-repeat;
  border-color: var(--black);
  outline: 0;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.tooltip {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: var(--white);
  padding: 12px;
  border-width: 2px;
  border-color: var(--blue);
  border-radius: 8px;
  border-style: solid;
  position: absolute;
  top: -150px;
  left: 20px;
  z-index: 1;
  opacity: 0;
  transition: all ease-out 0.5s;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.loader {
  width: 60px;
  height: 40px;
  transform: scale(1.4);
  position: relative;
  display: inline-block;
  --base-color: var(--white); /*use your base color*/
}
.loader::before {
  content: "";
  left: 0;
  top: 0;
  position: absolute;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--blue);
  background-image: radial-gradient(
      circle 8px at 18px 18px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 4px at 18px 0px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 4px at 0px 18px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 4px at 36px 18px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 4px at 18px 36px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 4px at 30px 5px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 4px at 30px 5px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 4px at 30px 30px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 4px at 5px 30px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 4px at 5px 5px,
      var(--base-color) 100%,
      transparent 0
    );
  background-repeat: no-repeat;
  box-sizing: border-box;
  animation: rotationBack 3s linear infinite;
}
.loader::after {
  content: "";
  left: 35px;
  top: 15px;
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--blue);
  background-image: radial-gradient(
      circle 5px at 12px 12px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 2.5px at 12px 0px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 2.5px at 0px 12px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 2.5px at 24px 12px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 2.5px at 12px 24px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 2.5px at 20px 3px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 2.5px at 20px 3px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 2.5px at 20px 20px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 2.5px at 3px 20px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 2.5px at 3px 3px,
      var(--base-color) 100%,
      transparent 0
    );
  background-repeat: no-repeat;
  box-sizing: border-box;
  animation: rotationBack 4s linear infinite reverse;
}

.small-loader {
  width: 60px;
  height: 40px;
  transform: scale(0.8);
  position: relative;
  display: inline-block;
  --base-color: var(--blue); /*use your base color*/
}
.small-loader::before {
  content: "";
  left: 0;
  top: 0;
  position: absolute;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--white);
  background-image: radial-gradient(
      circle 8px at 18px 18px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 4px at 18px 0px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 4px at 0px 18px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 4px at 36px 18px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 4px at 18px 36px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 4px at 30px 5px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 4px at 30px 5px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 4px at 30px 30px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 4px at 5px 30px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 4px at 5px 5px,
      var(--base-color) 100%,
      transparent 0
    );
  background-repeat: no-repeat;
  box-sizing: border-box;
  animation: rotationBack 3s linear infinite;
}
.small-loader::after {
  content: "";
  left: 35px;
  top: 15px;
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--white);
  background-image: radial-gradient(
      circle 5px at 12px 12px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 2.5px at 12px 0px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 2.5px at 0px 12px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 2.5px at 24px 12px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 2.5px at 12px 24px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 2.5px at 20px 3px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 2.5px at 20px 3px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 2.5px at 20px 20px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 2.5px at 3px 20px,
      var(--base-color) 100%,
      transparent 0
    ),
    radial-gradient(
      circle 2.5px at 3px 3px,
      var(--base-color) 100%,
      transparent 0
    );
  background-repeat: no-repeat;
  box-sizing: border-box;
  animation: rotationBack 4s linear infinite reverse;
}

.app-icon-cont {
  width: 170px;
  height: 170px;
  border-radius: 22%;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
}

.app-icon {
  width: 170px;
  height: 170px;
  border-radius: 22%;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
}

.appstore-cont {
  width: 180px;
  display: block;
}

.appstore-logos-container {
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-top: 24px;
  margin-bottom: 48px;
}

.appstore-title-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 8px;
}

.error-message {
  color: var(--red);
  background-color: var(--red15);
  padding: 16px 32px;
  font-weight: 600;
  border-radius: 8px;
  max-width: 736px;
  box-sizing: border-box;
}
.discount-error-message {
  color: var(--red);
  width: 100%;
  font-weight: normal;
  border-radius: 8px;
  max-width: 736px;
  margin-top: -12px;
}
.address-error-message {
  color: var(--red);
  background-color: var(--red15);
  padding: 16px 32px;
  margin: 0px auto;
  font-weight: normal;
  width: fit-content;
  text-align: center;
  border-radius: 8px;
  max-width: 736px;
  margin-top: 8px;
  margin-bottom: -24px;
}

.address-block {
  max-width: 736px;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 4px;
}

.discount-field-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  max-width: 736px;
}

.discount-field-container.added {
  padding: 0px 16px;
  border-radius: 8px;
  background-color: var(--light-grey);
  box-sizing: border-box;
  height: 56px;
}

.success-message {
  color: var(--green);
  background-color: var(--green15);
  padding: 16px 32px;
  font-weight: 600;
  border-radius: 8px;
}

.max-width-block {
  width: 100%;
  max-width: 736px;
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

@media only screen and (max-width: 990px) {
  .button-bubble {
    right: 0px;
  }
  h1 {
    font-size: 24px;
  }
  h1.padded {
    padding-right: 10vw;
    padding-left: 10vw;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 0px;
    margin-top: 5vh;
  }
  div.padded {
    padding-left: 16px;
    padding-right: 16px;
  }
  h2 {
    font-size: 16px;
  }
  h3 {
    font-size: 16px;
  }
  h4 {
    font-size: 14px;
  }

  .page-title {
    max-width: 512px;
  }

  .max-width-block {
    max-width: 512px;
  }

  body {
    font-size: 16px;
  }
  .small-print {
    max-width: 512px;
  }
  .price-s-print {
    max-width: 512px;
  }

  .navbar-cont {
    padding-top: 0px;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 0%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .navbar-spacing {
    height: 64px;
  }

  .footer-text {
    font-size: 16px;
  }
  .plan-button-checked {
    width: 100%;
    max-width: 512px;
  }
  .plan-button {
    width: 100%;
    max-width: 512px;
  }
  .plan-button-cont {
    flex-direction: column;
    gap: 24px;
    max-width: 512px;
  }
  .signup-block {
    /* flex-direction: column; */
    padding-left: 16px;
    padding-right: 16px;
    /* z-index: 100; */
    overflow: hidden;
  }
  .signup-block.discount {
    max-height: 148px;
    transition: none;
  }

  .signup-inner-container {
    padding-left: 16px;
    padding-right: 16px;
  }
  .age-cont {
    justify-content: space-between;
    max-width: 512px;
  }
  input[type="email"],
  input[type="password"],
  input[type="text"],
  input[type="tel"] {
    max-width: 512px;
  }
  input.wide {
    max-width: 512px;
  }

  select.full {
    width: 100%;
    max-width: 512px;
  }
  select.half {
    width: 100%;
    max-width: 512px;
  }
  .block-header,
  .block-width,
  .address-block {
    max-width: 512px;
  }
  .price-block {
    padding-right: 16px;
    padding-left: 16px;
  }
  .price-row {
    max-width: 512px;
  }
  .workshop-block {
    flex-direction: column-reverse;
  }
  .workshop-image {
    width: 100%;
  }
  .workshop-text-block {
    width: 100%;
    padding-top: 8px;
    padding-bottom: 32px;
  }
  .follow-button {
    margin-right: auto;
  }
  .box-block {
    max-width: 358.29px;
  }
  .box-image {
    object-fit: cover;
    height: 356px;
    max-width: 358.29px;
  }
  .box-name {
    font-size: 20px;
    left: 45%;
    top: 22%;
  }
  .box-name.large {
    font-size: 15px;
    left: 45%;
    top: 23.1%;
  }
  .error-message {
    max-width: 512px;
  }
  .discount-error-message {
    max-width: 512px;
  }
  .address-error-message {
    max-width: 512px;
  }
  .remove-discount {
    size: 24;
  }

  .discount-field-container {
    max-width: 512px;
  }
}

.page-cont {
  display: flex;
  flex-direction: row;
  gap: 32px;
  width: 90%;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
  margin-bottom: 32px;
  padding: 0 5% 0 5%;
}

.page-cont.mob {
  flex-direction: column;
}

.faq-cont {
  width: calc(66.6% - 16px);
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.faq-cont.mob {
  width: 100%;
}

.accordion {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--blue10);
  border-radius: 8px;
  cursor: pointer;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  cursor: pointer;
}

.accordion-content {
  padding: 16px;
  padding-top: 0px;
}

.contact-details-cont {
  width: calc(33.4% - 16px);
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px;
  height: fit-content;
  background-color: var(--offwhite);
  border: 2px solid var(--navy);
  border-radius: 8px;
}

.contact-details-cont.mob {
  width: 90%;
}

.returns-steps-grid {
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 32px;
  grid-column-gap: 32px;
}

.returns-steps-grid.mob {
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 32px;
}

.return-step-cont {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  background-color: var(--offwhite);
  border: 2px solid var(--pink);
  border-radius: 8px;
  color: black;
}
.return-step-cont.pink {
  border: 2px solid var(--pink);
}

.return-step-cont.yellow {
  border: 2px solid var(--yellow);
}

.return-step-cont.green {
  border: 2px solid var(--green);
}

.return-step-cont.navy {
  border: 2px solid var(--navy);
}
